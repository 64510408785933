header {
    .for-nav-sp {
        position: relative;
    }

    .header_img {
        background-image: url(../images/subpage/dobry-adwokat-w-darlowie.jpg);
        height: 760px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: flex;
        align-items: flex-end;

        .white-box {
            background-color: $white;
            padding: 49px 20px;
        }

        @media (min-width:575px) {
            .white-box {
                background-color: $white;
                padding: 55px;
            }
        }



        .green-box {
            background-color: $green;
            padding: 40px;
            text-transform: uppercase;

            img {
                width: 50px;
                margin-left: 15px;
            }
        }

        .no-pad {
            padding: 0;
            display: flex;
            align-items: flex-end;

            .green-box {
                color: $white;
                font-weight: 700;
            }
        }

    }

    @media(max-width:992px) {
        .header_img {
            height: auto;
        }

        .mobile-header {
            margin-top: 43%;
            display: flex;
        }
    }

    @media(min-width:992px) {
        .header_img {
            height: 69vh;
        }
    }
}



.end-section {
    color: $white;

    .inside {
        padding: 68px 0px;
    }
}

.extra-sec {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
    flex-direction: column;

    img {
        margin-bottom: 40px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
        margin-left: 20px;
    }

    h3 {
        margin-bottom: 20px;
    }

    @media (min-width:575px) {
        h3 {
            margin-left: -40px;
        }
    }
}

.contact-sec .extra-sec {
    flex-direction: row;
    align-items: unset;
}

.info-section-three {
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 750px;
    margin-top: 50px;

    .first-left {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        flex-basis: 40%;

        .extra-sec {
            display: flex;
            justify-content: center;
            align-content: center;
            margin-bottom: 50px;
            z-index: 3;
            position: relative;
        }

        .in-fl {
            height: 70%;
            background-color: $green;
            margin-top: 80px;
            padding: 80px;
            z-index: 2;
            padding: 20px 25px 20px 40px;
            display: flex;
            align-items: center;



            .in-fl_box {
                p {
                    color: $white;
                    margin-top: 48px;
                }
            }

            @media (min-width:992px) {
                .in-fl_box {
                    margin-left: 25%;
                    padding: 30px 15px 24px 0;
                }

            }

            @media (min-width:1400px) {
                .in-fl_box {
                    margin-left: 25%;
                    padding: 30px 30px;
                }

            }

        }

        @media (min-width:575px) and (max-width:992px) {
            .in-fl {
                width: 67%;
                margin: auto 0 0 auto;
            }

            .extra-sec {
                display: flex;
                justify-content: flex-end;
            }
        }

        @media (min-width:992px) {
            .in-fl {
                padding: 0 75px 0 0px;
            }

        }

        @media (min-width:1400px) {
            .in-fl {
                padding: 0 75px 0 60px;
            }

        }
    }



    .center-second {
        z-index: 1;
        margin: 0 auto;

        @media (max-width:767px) {
            img {
                width: 100%;
                height: auto;
                margin: 0 auto;
            }
        }
    }

    @media (min-width:768px) {
        .center-second {
            img {
                left: 0;
                right: 0;
                position: absolute;
                margin: 0 auto;
            }
        }
    }

    @media (min-width:575px) and (max-width:992px) {
        .center-second {
            position: absolute;
        }
    }

    .up-third {
        flex-basis: 54%;
        position: relative;
        z-index: 1;

        .right-third {
            background-color: $beige;
            margin-top: -110px;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;

            .rt-text {
                margin-top: 140px;
                margin-left: 10%;
                margin-right: 50px;
            }

            @media (min-width:768px) {
                .rt-text {
                    margin-left: 50%;
                }
            }
        }
    }

}

@media (min-width:992px) {
    .info-section-three {
        margin-top: 134px;

        .up-third {
            z-index: 0;
        }
    }
}

@media (min-width:920px) {
    .info-section-three {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width:920px) and (max-width:1200px) {
    .info-section-three {
        margin-top: 218px;
    }
}



.tell-me {
    margin-top: 45px;

    .extra-sec {
        margin-top: 25px;
        //        justify-content: center;
    }

    .infoboxes {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        .infobox {
            display: flex;
        }
    }

    @media (min-width:768px) {
        .infoboxes {
            margin-top: 0px;
        }
    }
}

@media (min-width:575px) {
    .tell-me {
        margin-top: 175px;
    }
}

.sides-text {
    margin-top: 80px;
    margin-bottom: 80px;
}

.spec-boxes {
    margin-bottom: 195px;

    .sb-header {
        color: $green;
        font-weight: 700;
    }

    .sb-box {
        margin-bottom: 40px;

        img {
            margin-bottom: 20px;
        }
    }

    @media (min-width: 575px) {
        .sp-box {
            margin-bottom: 0px;
        }
    }
}

@media (max-width:575px) {
    .contact-sec {
        margin-top: 105px;
    }
}

@media (max-width:575px) {
    .price-sec {
        margin-top: 145px;
    }
}


.contact-page {
    .header_sec {
        margin-top: 70px;
    }

    .infoboxes-contact {
        .infobox {
            display: flex;
            align-items: center;

            img {
                margin-right: 15px;
                margin-bottom: 10px;
                margin-top: 10px;
                width: 100%;
                height: auto;
                max-width: 29px;
            }

            a {
                color: $black;
            }


        }
    }

    .extra-sec {
        margin-top: 50px;
    }

    .header_sec {
        margin-bottom: 50px;
    }

    .contact-main {
        margin-bottom: 40px;

        .privacy {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            label {
                margin-left: 10px;
            }

            p {
                font-size: .5rem;
                line-height: 11px;
            }
        }
    }

    @media (max-width:992px) {
        .infoboxes-contact {
            margin-bottom: 50px;
        }
    }
}

.contact-main .send-btn {
    margin-top: -10px !important;
}

.pricing {
    margin-bottom: 100px;

    h1 {
        font-size: 24px;
        width: 100%;
        margin-left: 40px;
    }

    @media (max-width:768px) {
        .header_sec {
            margin-top: 40px;
        }
    }

    .price-box {
        min-height: 400px;

        position: relative;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.24);

        .price {
            padding-top: 18px;
            padding-bottom: 18px;
            font-size: 28px;
            font-weight: 600;
            background-color: #01532d;
            color: #ffffff;
        }

        ul {
            text-align: center;
            margin: 0 auto;
            padding-left: 0;
        }

        li {
            list-style: none;

        }

        ul li:before {
            background-image: none;
        }

        h2 {
            font-size: 20px;
            text-align: center;
            color: $black;
            margin-top: 30px;
            font-weight: 400;
            font-family: Helvetica Neue LT Pro\ 45 Light;
            margin-bottom: 40px;
        }

        p {
            width: 100%;
            text-align: center;
        }

        .a-div {
            display: flex;
            justify-content: center;
            width: 100%;

            a {
                text-align: center;
                display: block;
                margin: 0 auto;
                position: absolute;
                bottom: 30px;
                font-size: 13px;
            }

            @media(min-width:992px) {
                a {
                    bottom: 30px;
                    font-size: 17px;
                }
            }
        }
    }

    @media (min-width:992px) {
        .price-box {
            margin: 0 20%;
        }
    }

    .pb-img {
        background-image: url(../../build/images/icons/path.png);
        background-position: center;
        height: 180px;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        background-size: 165px;

        p {
            color: $white;
            font-size: 28px;
        }
    }
}

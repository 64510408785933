html,
body {
    font-size: 16px;
    background: #ffffff;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

hr {
    margin-top: 60px;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro 45 Light';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Pro 45 Light'), url('../../fonts/HelveticaNeueLTProLt.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Neue LT Pro 67 Medium Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Pro 67 Medium Condensed'), url('../../fonts/HelveticaNeueLTProMdCn.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Neue LT Pro 75 Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Pro 75 Bold'), url('../../HelveticaNeueLTProBd.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Neue LT Pro 57 Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Pro 57 Condensed'), url('../../fonts/HelveticaNeueLTProCn.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Neue LT Pro 25 Ultra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Pro 25 Ultra Light'), url('../../fonts/HelveticaNeueLTProUltLt.woff') format('woff');
}

p,
a,
ul,
li {
    font-size: 0.9rem;
    font-family: 'Helvetica Neue LT Pro 45 Light';
    letter-spacing: 2px;
}



.light {
    font-family: 'Helvetica Neue LT Pro 25 Ultra Light';
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Helvetica Neue LT Pro 75 Bold';
}




nav ul li {
    list-style: none !important;
    position: relative;
}

main ul li:before {
    content: '';
    background-image: url(../images/icons/shield.png);
    height: 15px;
    width: 18px;
    position: absolute;
    left: -28px;
    top: 4px;
    background-position: 0px 0px;
    background-size: 16px;
    background-repeat: no-repeat;
}


.box-li ul li {
    list-style: none;
    position: relative;
}

.box-li ul li:before {
    content: '';
    background-image: url(../images/icons/shield.png);
    height: 15px;
    width: 18px;
    position: absolute;
    left: -28px;
    top: 4px;
    background-position: 0px 0px;
    background-size: 16px;
    background-repeat: no-repeat;
}

.mi-header {
    font-size: 2.3rem;
    color: $green;
}

@media (min-width:992px) {

    .mi-header {
        font-size: 3rem;
        color: $green;
    }
}

.bolder {
    font-family: 'Helvetica Neue LT Pro 67 Medium Condensed';
}




h1 {
    font-size: 3.2rem;
}


h2 {
    font-size: 2.85rem;
    color: $green;
}

h3 {
    font-size: 2rem;
}

@media (max-width:575px) {
    h1 {
        font-size: 2.87rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.4rem;
    }
}

@media (max-width:1324px) {
    h1 {
        font-size: 2.4rem;
    }

    h2 {
        font-size: 1.9rem;
    }

    h3 {
        font-size: 1.6rem;
    }
}

.white {
    color: $white;
}

.for-nav {
    position: absolute;
}

.spec-header {
    font-size: 2.85rem;
    color: $green;
}

@media (min-width:575px) and (max-width:1025px) {
    .mobile-logo {
        position: absolute;
        opacity: 0.6;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1180px;
    }
}

.green-bg {
    background-color: $green
}

.no-pad {
    padding: 0;
}

.infobox_nav {
    background-color: $white;
    float: right;
    margin-right: -15px;
    text-align: right;
    position: relative;
    z-index: 911111111111;

    a {
        padding: 30px 55px;
        height: 100%;
        display: flex;
        color: $green;

        .infobox_in {
            display: flex;

            .if_inn {
                display: flex;
                flex-direction: column;
            }
        }

        img {
            width: 36px;
            height: 40px;
            display: block;
            margin-left: 15px;
            margin-top: 5px;
        }
    }

    a:hover {}
}

nav {
    width: 100%;

    .navbar-collapse ul {
        li {
            a {
                color: $black;
                padding: 30px 35px;
                display: block;
                transition: 0.4s;
            }

            .nav-link {
                color: $white;
            }
        }

        li a:hover {
            background-color: #0b452fed;
            color: $white !important;
            padding: 20px;
            margin-top: 10px;
            transition: 0.4s;
        }
    }

    .ib-mobile {}

    @media (min-width:992px) {
        .ib-mobile {
            display: none;
        }
    }

    .infobox {
        background-color: $white;
        float: right;
        text-align: right;
        position: relative;
        z-index: 1111111;

        a {
            padding: 30px 20px;
            height: 100%;
            display: flex;
            color: $green;

            .infobox_in {
                display: flex;

                .if_inn {
                    display: flex;
                    flex-direction: column;
                }
            }

            img {
                width: 36px;
                height: 40px;
                display: block;
                margin-left: 15px;
                margin-top: 5px;
            }
        }
    }

    @media (min-width:992px) {
        .infobox {
            margin-right: -15px;
        }
    }

    @media (max-width:992px) {
        .ib-full {
            display: none;
        }

        a {
            //            padding: 30px 55px;
        }
    }

    .nav-cont {
        display: flex;
        align-items: center;
    }
}

@media (min-width:992px) {
    nav {
        width: 100%;
    }
}

.for-intro {
    margin-top: 70px;
    margin-bottom: 70px;
}

.left-index-header {
    margin-top: 30px;
}

.sec-right {
    padding-left: 5px;

    .mini-h {
        font-size: 1.80rem;
        margin-bottom: 20px;
    }

    .button-mt {
        margin-top: 40px;
        display: inline-block;
    }
}

@media (max-width:575px) {
    .sec-right {
        margin-top: 40px;
    }
}


@media (min-width:1024px) {
    .sec-right {
        padding-left: 70px;

    }
}

.middle {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
}

.button {
    background-color: $green;
    text-transform: uppercase;
    font-weight: 500;
    padding: 20px;
    color: $white;
    transition: 0.5s;
    display: inline-block;
    margin-top: 40px;
}

.button:hover {
    font-size: 15px;
    text-decoration: none;
    transition: 0.5s;
    color: $white;
    font-weight: 700;
}

.button:after {
    content: '';
    background-image: url(../images/icons/right-arrow.svg);
    height: 20px;
}

.button-green {
    border: 1px solid $white;
    padding: 14px 30px;
    margin-top: 35px;
    display: inline-block;
    background-color: $green;
    color: $white;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
}

.button-green:hover {
    text-decoration: none;
    font-size: 18px;
    transition: 0.5s;
    color: $white;
    font-weight: 700;
}

.button-white {
    border: 1px solid $white;
    padding: 14px 30px;
    margin-top: 35px;
    display: inline-block;
    border: 1px solid $white;
    color: $white;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}


.button-white:hover {
    background-color: $white;
    border: 1px solid $white;
    color: $green;
    text-decoration: none;
}

/* Blog */
.wp_sec {
    hr {
        margin-top: 20px;
    }

    .wp_image {
        img {
            object-fit: cover;
            width: 100%;
            height: auto;
            max-height: 120px;
        }
    }

    p {
        font-size: 11px;
        margin-bottom: -7px;
    }
}

.box-li {


    ul {
        width: 100%;

        li {
            font-size: .8rem;
        }
    }
}

@media (min-width:575px) {
    .box-li {
        display: flex;

        ul {
            width: 50%;
        }
    }
}






@supports(-webkit-appearance: none) or (-moz-appearance: none) {

    input[type='checkbox'],
    input[type='radio'] {
        --active: #275EFE;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, .3);
        --border: #BBC1E1;
        --border-hover: #275EFE;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background .3s, border-color .3s, box-shadow .2s;

        &:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
        }

        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: .3s;
            --d-t: .6s;
            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
        }

        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: .9;

            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }

            & + label {
                cursor: not-allowed;
            }
        }

        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }

        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }

        &:not(.switch) {
            width: 200px;

            &:after {
                opacity: var(--o, 0);
            }

            &:checked {
                --o: 1;
            }
        }

        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
    }

    input[type='checkbox'] {
        &:not(.switch) {
            border-radius: 7px;

            &:after {
                width: 5px;
                height: 9px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 7px;
                top: 4px;
                transform: rotate(var(--r, 20deg));
            }

            &:checked {
                --r: 43deg;
            }
        }

        &.switch {
            width: 38px;
            border-radius: 11px;

            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }

            &:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }

            &:disabled {
                &:not(:checked) {
                    &:after {
                        opacity: .6;
                    }
                }
            }
        }
    }

    input[type='radio'] {
        border-radius: 50%;

        &:after {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: var(--active-inner);
            opacity: 0;
            transform: scale(var(--s, .7));
        }

        &:checked {
            --s: .5;
        }
    }
}


.index-map {
    margin-top: 5rem;
}

@media (min-width:992px) {
    .spec-button {
        margin-top: 25px;
    margin-left: 80px;
    }
}

.to-top{
    img{
         width: 18px;
    float: right;
    margin-right: 30px;
    }
}

.wp-button{
    img{
        width: 18px;
        height: auto;
        margin-left: 25px;
    }
}

footer {



    span {
        display: block;
    }



    .small {
        font-family: 'Helvetica Neue LT Pro 57 Condensed';
        text-transform: uppercase;
        color: $greentxt;
        font-weight: 700;
        margin-bottom: 15px;
        margin-top: 50px;
    }

    .info {
        display: flex;
        align-items: center;

        p,
        a {
            margin-bottom: 0;
            color: $grey;

            img {
                margin-right: 10px;
            }
        }

        p:first-of-type {
            margin-left: 10px;
        }
    }
}

@media (min-width:992px) {
    footer {
        margin-top: 150px;

        .f-el {
            text-align: left;
        }

        .small {
            margin-bottom: 30px;
            margin-top: 0px;
        }
    }
}

.copyright {
    margin-top: 120px;
    margin-bottom: 60px;
    text-transform: uppercase;
    font-family: Helvetica Neue LT Pro\ 45 Light;
    letter-spacing: 2px;

    a {
        font-family: Helvetica Neue LT Pro\ 45 Light;
    }

    .f-el {
        text-align: center;
    }

    a,
    .f-el {
        color: $tcolor;
        font-size: 0.5rem;
    }

    @media (min-width:992px) {
        .f-el {
            text-align: left;
        }
    }

    .f-el {
        align-items: center;
        display: flex;
    }

    .f-img {
        justify-content: flex-end;

        a {
            margin-left: 5px;
            margin-top: 2px;

            img {
                width: 42px;
                height: auto;
            }
        }

        .to-top {
            img {
                width: 12px;
                position: absolute;
                right: 0;
                bottom: -20px;
            }
        }
    }


    @media (max-width:768px) {
        .f-el {
            text-align: center;
            justify-content: center;
        }
    }

}

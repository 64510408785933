// Global Sass variables

// fonts
$font-main: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$font-code: monospace;
$font-size: 100%;
$font-size-rwd: calc(0.8em + 0.5vmax);

// colors
$black: #000000;
$white: #ffffff;
$tcolor: #a3a9a9;
$green: #003f22;
$grey: #2b3434;
$white: #ffffff;
$greentxt: #597569; 
$color-text: #444;
$color-foot: #cbd;
$color-code: #eee;
$color-link: #00c;
$color-hovr: #c00;
$beige: #f5f1f4;

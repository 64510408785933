.navbar-sp {
    width: 100%;

    .navbar-brand {

        img {
            height: 70px;
            width: auto;
        }
    }

    @media (min-width:758px) {
        .navbar-brand {
            margin-right: 110px;
        }
    }

    .navbar-nav {
        background-color: #ffffff;
        margin-left: -15px;
        padding-left: 34px;
    }

    .navbar-collapse {
        z-index: 111;
        position: absolute;
        width: 100%;
        margin-top: 53px;
    }

    @media (min-width:992px) {
        .navbar-collapse {
            position: relative;
            width: auto;
            margin-top: auto;
        }
    }

    .collapsing {
        display: none;
    }

    .show {
        .navbar-nav {
            width: 100%;
            position: absolute;
            transition: 0.5s;
        }

        @media (max-width:768px) {
                .navbar-nav {
                    margin-top: 45px;
                }
            }
        }
    }

    @media (max-width:575px) {
        .index {
            margin-top: 20px;

            .logo-place {
                width: 100%;

                img {
                    width: 60px;
                    display: block;
                    float: right;
                }
            }
        }
    }

    .navbar {
        position: relative;
        z-index: 99999999999999;

        .collapsing {
            background-color: white;
            margin-left: -15px;
            padding-left: 15px;
            margin-top: 15px;
        }

        .show {
            background-color: white;
            margin-left: -15px;
            padding-left: 15px;
            margin-top: 15px;
        }
    }

    @media (min-width:575px) and (max-width:978px) {
        .navbar {
            margin-top: 20px;
        }
    }

header {
    height: auto;
}

.with-dots {
    background-image: url(../images/icons/dots.png);
    background-repeat: no-repeat;
    background-position: -14% 50%;
}

.quote {
    margin-top: 90px;
    p{
        font-size: 1.2rem;
        text-align: right;
    }
    .subtitle{
        font-size: 10px;
        text-align: right;
    }
} 

.firstrow {
    .firstrow_img {
        background-image: url(../images/main/adwokat-karolina-warzechowska.jpg);
        background-size: cover;
        height: 54vh;
        background-position: -66px 103px;
        background-repeat: no-repeat;
    }

    .logo-place {
        img {
            margin: 0 auto 0 35px;
            display: block;
        }
    }

    @media (min-width:575px) {
        .firstrow_img {
            height: 82vh;
            background-position: -66px 103px;
        }
    }

    @media (min-width:1024px) {
        .firstrow_img {
            background-position: center;
        }
    }
}

.dots {
    background-image: url(../images/icons/dots.svg);
    background-repeat: no-repeat;
    background-size: 50% 100%;
}

.boxes {
    margin-bottom: 110px;
    margin-top: 80px;

    .boxes_top {


        .boxes_title {
            flex-basis: 40%;
            background-color: $green;
            display: flex;
            padding: 40px;

            .box_head {
                font-size: 1.8rem;
                text-transform: uppercase;
                color: $white;
            }

            @media (min-width:992px) {
                .box_head {
                    padding-right: 50px;
                }

            }
        }

        .boxes_desc {
            flex-basis: 70%;
            justify-content: center;
            padding: 30px 0;

            display: flex;
            align-items: center;
        }

        @media (min-width:992px) {
            .boxes_desc {
                padding: 125px 55px;
                max-height: 265px;
            }
        }
    }

    @media (min-width:992px) {
        .boxes {
            margin-top: 150px;
        }

        .boxes_title {
            align-items: center;
            justify-content: center;
            padding: 0;
        }

        .boxes_top {
            display: flex;
        }
    }

    .boxes_sq {
        display: flex;
        flex-wrap: wrap;

        .boxes_team {
            flex-basis: 100%;
            background-image: url(../images/main/adwokat-darlowo.jpg);

            .b1 {
                margin: 0 0 0 50%;
            }
        }

        .boxes_team_2 {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 100%;
        }

        .boxes_spec {
            height: 267px;
            width: 50%;
            border: 1px solid grey;
            text-transform: uppercase;
            background-color: $white;
            align-items: flex-end;
            display: flex;
            color: $black;
            transition: 0.5;


            p {
                margin-left: 10px;
                max-width: 140px;
                margin-bottom: 45px;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 30px;
            }
        }

        .boxes_spec:hover {
            background-color: $green;
            text-decoration: none;
            color: $white;
            transition: 0.5;
        }
    }



    @media (min-width:992px) {
        .boxes_sq {
            flex-wrap: nowrap;

            .boxes_spec {
                p {
                    margin-left: 40px;
                    font-size: 1.8rem;
                }
            }

            .boxes_team {
                flex-basis: 50%;
            }

            .boxes_team_2 {
                flex-basis: 50%;
            }
        }
    }



    .add-text {
        .header {
            margin: 50px 0;
        }
    }

    .intro {

        .no-pad {
            padding: 0px;
            position: relative;
        }
    }

    @media (min-width:1024px) {
        .intro {
            margin-top: 100px;
        }
    }
}

.intro {
    .individ {
        margin-top: 80px;
    }
}

@media (min-width:1024px) {
    .intro {
        margin-top: 100px;
    }
}

@media (min-width:992px) {
    .intro {
        margin-top: 0;

        .individ {
            margin-top: 0;
        }
    }
}

@media (max-width: 575px) {
    .add-text {
        margin-bottom: 20px;
    }

    .bolder {
        margin-bottom: 20px;
    }

}

.office {
    background-image: url(../images/main/prawnik-koszalin.jpg);
    background-repeat: no-repeat;
    height: 777px;
    background-size: cover;
}

.office:after {
    background-size: 150px;
    content: url('../images/icons/icon-small.svg');
    display: block;
    height: 40px;
    position: absolute;
    right: -10px;
}

.appointment {
    background-image: url(../images/main/prawnik-darlowo.jpg);
    background-repeat: no-repeat;
    height: 358px;
    margin-top: 80px;

    .app_boxLink {
        background-color: $green;
        color: $white;
        padding: 35px 30px;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        font-weight: 600;
    }


    .individ {
        margin-top: 145px;
    }
}

@media (min-width:992px) {
    .appointment {
        margin-top: auto;
    }
}

.spec-intro {
    margin-top: 30px;
    @media (min-width:992px) {
        margin-top: 100px;
    } 
    
    @media (max-width:992px) {
        .extra-sec {
            margin-bottom: 0 !important;
        }
    }

    .extra-sec {
        align-items: flex-start;
        flex-direction: row;
    }

    .infoboxes {
        .infobox {
            margin-bottom: 20px;
        }
    }

    @media (max-width:992px) {
        .infoboxes {
            margin-top: 40px;
        }
    }
}

.specs {
    .spec-line {
        margin-bottom: 40px;



        .spec-title {
            position: absolute;
            bottom: 0;
            left: 21px;
            color: #fff;
            font-size: 2rem;
        }

        @media (min-width:575px) {
            .spec-title {
                position: absolute;
                bottom: 75px;
                left: 21px;
                font-size: 2rem;
            }
        }

        @media (min-width:768px) {
            .spec-title {
                top: 65px;
                left: 40px;
                font-size: 1.2rem;
            }
        }

        @media (min-width:1240px) {
            .spec-title {
                bottom: 0;
                left: 40px;
                font-size: 2rem;
                top: inherit;
            }
            
            .st-work{
                bottom: 130px;
            }
        }



        @media (max-width:575px) {
            img {
                width: 65%;
            }
        }

    }

    @media (max-width:765px) {
        .spec-box-item {
            margin-top: 30px;
        }
    }
}
